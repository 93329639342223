import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';

import './src/styles/global.css';
import { Seo } from 'components/Seo';

export const onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const headComponents = getHeadComponents();
  // eslint-disable-next-line
  headComponents.push(
    <link
      rel="preload"
      href="/fonts/be-vietnam-pro-300.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />,
    <link
      rel="preload"
      href="/fonts/be-vietnam-pro-400.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />,
    <link
      rel="preload"
      href="/fonts/be-vietnam-pro-500.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />,
    <link
      rel="preload"
      href="/fonts/be-vietnam-pro-800.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />,
    <link
      rel="preload"
      href="/fonts/be-vietnam-pro-900.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  replaceHeadComponents(headComponents);
};

export const wrapRootElement = ({ element }) => (
  <div id="root">
    <ThemeProvider theme={theme}>
      <Seo
        title="WireBuddy"
        description="All your jobs, simply in one place"
        lang="en"
        image="src/assets/svgs/WireBuddyLogoPartial.svg"
      />
      {element}
    </ThemeProvider>
  </div>
);
