import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const Seo = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
  image,
}: ISEOProps) => {
  const { site } = useStaticQuery<IQueryTypes>(SEOStaticQuery);

  const metaDescription = description || site.siteMetadata.description;
  const author = site.siteMetadata?.author;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${image}`,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: author,
        },
        {
          name: 'twitter:title',
          content: defaultTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: `${image}`,
        },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ].concat(meta)}
    >
      <link
        rel="preload"
        href="/fonts/be-vietnam-pro-300.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/be-vietnam-pro-400.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/be-vietnam-pro-500.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/be-vietnam-pro-800.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/be-vietnam-pro-900.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
const SEOStaticQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`;
