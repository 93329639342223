// Our primary color is blue[0] #0F3570
// Our secondary color is yellow[0] #F7A214

const colors: IColorPalette = {
  // NOTE: When changing this file, change globals.css as well
  blue: [
    '#0F3570', // primary
    '#0C2448', // shade
    '#23529A', // tint
    '#A7DAFF', // 3
    '#32B3B9', // 4
    '#CFD7E2', // 5
  ],

  purple: [
    '#AC5FDD', // 0
    '#7A3E9E', // 1
  ],

  yellow: [
    '#F7A214', // 0
    '#F78F14', // 1
    '#FFEAC7', // 2
  ],

  grey: [
    '#6E808F', // 0
    '#79859E', // 1
    '#F3F4F6', // 2
    '#E0E0E0', // 3
    '#ECEEF7', // 4
    '#BABAC0', // 5
    '#FBF8F4', // 6
    '#54606b', // 7
    '#707070', // 8
    '#333333', // 9
  ],

  green: [
    '#518C6C', // 0
    '#CAEDDA', // 1
    '#25B467', // 2
  ],

  red: [
    '#E90909', // 0
    '#F51F5A', // 1
  ],

  rgba: [
    'rgba(243, 244, 246, 0.9)', // 0
    'rgba(243, 244, 246, 0.5)', // 1
    'rgba(243, 244, 246, 0.7)', // 2
    'rgba(243, 244, 246, 0.8)', // 2
  ],

  white: '#FFFFFF',

  black: '#000000',
};

export default colors;
